import React, { useState } from 'react'
import clsx from 'clsx'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeart,
  faXmark,
  faCheck,
  faAward
} from '@fortawesome/free-solid-svg-icons'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import PaymentModal from '../../components/PaymentModal'
import BackToTopButton from '../../components/BackToTopButton'
import Link from '../../components/LinkWrapper'
import ScrollOnAnimation from '../../components/ScrollOnAnimation'
import Markdown from '../../components/Markdown'
import Carousel from '../../components/Carousel'
import Testimony from '../../components/Testimony'
import i18n from '../../locales/he.yaml'
import { fireEvent, useVideo } from '../../analytics'
import { getParams } from '../../utils'

const course = i18n.courses.nonAcademyCourses.find(
  (course) => course.name === 'ספרינט פיסול דמויות בבצק סוכר'
)

const FiguresSculptureQuery = graphql`
  query FiguresSculptureQuery {
    image1: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-הדמויות-אריה-דובי-דינוזאור-אפרוח-ארנב-שמפוסלים-בבצק-סוכר.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    image2: file(
      relativePath: {
        eq: "אריה-דובי-ארנב-דינוזאור-ואפרוח-מפוסלים-בבצק-סוכר.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image3: file(relativePath: { eq: "דינוזאור-נאיבי-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image4: file(relativePath: { eq: "דובי-נאיבי-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image5: file(relativePath: { eq: "ארנב-נאיבי-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image6: file(relativePath: { eq: "אריה-נאיבי-2-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image7: file(relativePath: { eq: "אפרוח-נאיבי-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image8: file(
      relativePath: {
        eq: "יוטי-עם-עוגת-בצק-סוכר-הראשונה-שלה-עם-הדמויות-דובי-אריה-אפרוח-דינוזאור-כלב.jpeg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image9: file(
      relativePath: { eq: "צילום-תהליך-של-פיסול-אריה-בבצק-סוכר.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image10: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תעודת-סיום.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product1: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product2: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product3: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product4: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product5: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product6: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-6.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product7: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-7.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product8: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-8.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product9: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-9.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product10: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-10.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product11: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-11.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product12: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-12.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product13: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-13.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product14: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-14.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product15: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-15.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    product16: file(
      relativePath: { eq: "ספרינט-פיסול-דמויות-בבצק-סוכר-תוצר-תלמידה-16.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    survival: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-הישרדות-ענקית-מרחפת-בגובה-מטר-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
  }
`

const FiguresSculptureTemplate = ({ pathname, data, search, product }) => {
  const queryParams = getParams(search)
  const [isPaymentForm, setIsPaymentForm] = useState(
    queryParams.paymentMode === 'dana'
  )
  const iframeRef = useVideo(product)
  const {
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    product1,
    product2,
    product3,
    product4,
    product5,
    product6,
    product7,
    product8,
    product9,
    product10,
    product11,
    product12,
    product13,
    product14,
    product15,
    product16,
    survival
  } = data

  const BULLETS_IMAGE = [image1]

  const WARMUP_IMAGE = [image8, null, image6]

  const HOW_IT_WORKS_IMAGE = [image9, image5]

  const WHO_IS_IT_FOR_IMAGE = [image3, image6]

  const STUDENTS_IMAGES = [
    product1,
    product2,
    product3,
    product4,
    product5,
    product6,
    product7,
    product8,
    product9,
    product10,
    product11,
    product12,
    product13,
    product14,
    product15,
    product16
  ]

  const CTA = () => (
    <a
      href='#registration'
      className='button is-nude is-medium'
      onClick={() =>
        fireEvent('InternalClick', product, {
          to: '#registration'
        })
      }
    >
      {course.callToAction}
    </a>
  )

  return (
    <>
      {isPaymentForm && (
        <PaymentModal
          onClose={() => setIsPaymentForm(false)}
          welcomeImage={image2}
          successLabel={course.successLabel}
          course={course}
          paymentMode={queryParams.paymentMode}
        />
      )}
      <BackToTopButton selectorId='figures-sculpture' />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/academy/'}>{i18n.header.academy}</Link>
                </li>
                <li className='is-active'>
                  <Link to={pathname} aria-current='page'>
                    {course.name}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column is-8 is-full-touch'>
                <h1 className='is-size-4 has-text-nude'>{course.subtitle}</h1>
                <h2
                  className={clsx('is-size-4', {
                    'is-margin-bottom-5': !course.hook
                  })}
                >
                  {course.title}
                </h2>
                {course.hook && (
                  <p className='is-margin-bottom-5'>
                    <span className='tag is-nude is-light is-large'>
                      {course.hook}
                    </span>
                  </p>
                )}
                <p>
                  <CTA />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {course.bullets.map((bullet, index) => (
        <div
          key={index}
          className={clsx('hero', {
            'has-background-nude-lighter': index === 1
          })}
        >
          <div className='hero-body'>
            <div className='container'>
              <div
                className={clsx(
                  'columns is-mobile is-multiline is-centered is-vcentered',
                  {
                    'direction-row-reverse': index % 2 === 1
                  }
                )}
              >
                <div
                  className={clsx(
                    'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen',
                    {
                      'is-offset-1-widescreen': index % 2 === 1
                    }
                  )}
                >
                  <GatsbyImage
                    image={getImage(BULLETS_IMAGE[index])}
                    title={bullet.image.title}
                    alt={bullet.image.alt}
                    className='live-image'
                    style={{
                      maxWidth: getImage(BULLETS_IMAGE[index]).width,
                      margin: '0 auto' // used to center the image
                    }}
                    loading='eager'
                  />
                  <div className='is-flex justify-center content'>
                    <p className='has-text-centered has-text-gold-black'>
                      {bullet.image.title}
                    </p>
                  </div>
                </div>
                <div
                  className={clsx(
                    'column is-full-mobile is-10-tablet is-6-desktop',
                    {
                      'is-offset-1-widescreen': index % 2 === 0
                    }
                  )}
                >
                  <div className='content'>
                    <h4 className='has-text-centered-touch'>{bullet.title}</h4>
                    <p>
                      <Markdown>{bullet.description}</Markdown>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <ScrollOnAnimation>
        <div className='hero'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <CTA />
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      {[course.warmup[0], { video: true }, course.warmup[1]].map(
        (bullet, index) =>
          bullet.video ? (
            <ScrollOnAnimation key={index}>
              <div className='hero'>
                <div className='hero-body is-padding-bottom-0'>
                  <div className='container content has-text-centered'>
                    <div className='columns is-centered is-vcentered'>
                      <div className='column is-full-real-mobile is-12-tablet is-10-desktop is-8-widescreen'>
                        <figure
                          className='image is-16by9'
                          style={{ marginRight: 0, marginLeft: 0 }}
                        >
                          <iframe
                            className='has-ratio'
                            width='640'
                            height='360'
                            src={`https://player.vimeo.com/video/943991463?h=826545297f&badge=0&autopause=0player_id=0&app_id=58479`}
                            frameBorder='0'
                            allow='autoplay; fullscreen; picture-in-picture'
                            ref={iframeRef}
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ScrollOnAnimation>
          ) : (
            <ScrollOnAnimation key={index}>
              <div
                id={index === 0 ? 'figures-sculpture' : undefined}
                className={clsx('hero', {
                  'has-background-nude-lighter': index === 0
                })}
              >
                <div className='hero-body'>
                  <div className='container'>
                    <div
                      className={clsx(
                        'columns is-mobile is-multiline is-centered is-vcentered',
                        {
                          'direction-row-reverse': index === 2
                        }
                      )}
                    >
                      <div
                        className={clsx(
                          'column is-full-mobile is-10-tablet is-6-desktop',
                          {
                            'is-offset-1-widescreen': index === 2
                          }
                        )}
                      >
                        <div className='content'>
                          <h4 className='has-text-centered-touch'>
                            {bullet.title}
                          </h4>
                          <p>
                            <Markdown>{bullet.description}</Markdown>
                          </p>
                        </div>
                      </div>
                      <div
                        className={clsx(
                          'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen',
                          {
                            'is-offset-1-widescreen': index === 0
                          }
                        )}
                      >
                        <GatsbyImage
                          image={getImage(WARMUP_IMAGE[index])}
                          title={bullet.image.title}
                          alt={bullet.image.alt}
                          className='live-image'
                          style={{
                            maxWidth: getImage(WARMUP_IMAGE[index]).width,
                            margin: '0 auto' // used to center the image
                          }}
                          loading='eager'
                        />
                        <div className='is-flex justify-center content'>
                          <p className='has-text-centered has-text-gold-black'>
                            {bullet.image.title}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ScrollOnAnimation>
          )
      )}
      <ScrollOnAnimation>
        <div className='hero'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <CTA />
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <div className='hero'>
        <div className='hero-body is-padding-bottom-0'>
          <div className='container content has-text-centered'>
            <h3>{course.studentsProducts.title}</h3>
          </div>
        </div>
      </div>
      <Carousel cycle={4000}>
        {STUDENTS_IMAGES.map((image, index) => (
          <div key={index} className='hero'>
            <div className='hero-body'>
              <div
                className='container content has-text-centered'
                style={{ height: '100%' }}
              >
                <div
                  className='columns is-mobile is-centered is-vcentered'
                  style={{ height: '100%' }}
                >
                  <div className='column is-5-widescreen is-7-desktop is-9-tablet is-11-mobile'>
                    <GatsbyImage
                      image={getImage(image)}
                      title={course.studentsProducts.image.title}
                      alt={course.studentsProducts.image.alt}
                      style={{
                        maxWidth: getImage(image).width,
                        margin: '0 auto' // used to center the image
                      }}
                      loading='eager'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <div className='hero'>
        <div className='hero-body is-padding-bottom-0 is-padding-top-0'>
          <hr className='has-background-nude-light' />
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <ScrollOnAnimation>
              <div className='has-text-centered is-margin-bottom-6'>
                <h3 className='has-text-nude'>{course.plan.title}</h3>
              </div>
            </ScrollOnAnimation>
            <div className='extras-item'>
              <ScrollOnAnimation>
                <div className='has-text-centered is-margin-bottom-6'>
                  <h4>{course.plan.abc.title}</h4>
                  <h6>{course.plan.abc.subtitle}</h6>
                  <p>
                    <Markdown>{course.plan.abc.description}</Markdown>
                  </p>
                </div>
              </ScrollOnAnimation>
              <div className='columns is-multiline is-mobile is-centered is-vcentered'>
                <ScrollOnAnimation>
                  <div className='column is-6-desktop is-full-tablet'>
                    <div className='accordion is-fixed-on-open'>
                      <p>{course.plan.abc.prefix}</p>
                      <input type='checkbox' id='abc' hidden />
                      <label
                        className='accordion-header is-margin-left-3'
                        htmlFor='abc'
                      >
                        <a className='button is-text has-text-weight-bold'>
                          {course.plan.expand}
                        </a>
                      </label>
                      {course.plan.abc.bullets.map((bullet) => (
                        <div key={bullet.title} className='is-relative'>
                          <FontAwesomeIcon
                            icon={faHeart}
                            className='has-text-nude is-size-7 is-absolute'
                            style={{ top: 8 }}
                          />
                          <div className='is-margin-left-5'>
                            <p className='accordion-mark'>{bullet.title}</p>
                            <div className='accordion-body'>
                              <p className='is-margin-bottom-4'>
                                <Markdown>{bullet.description}</Markdown>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                      <p>{course.plan.abc.suffix}</p>
                    </div>
                  </div>
                </ScrollOnAnimation>
                <ScrollOnAnimation>
                  <div className='column is-10-mobile is-8-tablet is-5-desktop is-offset-1-desktop'>
                    <GatsbyImage
                      image={getImage(image4)}
                      title={course.plan.abc.image.title}
                      alt={course.plan.abc.image.alt}
                      className='live-image'
                      style={{
                        maxWidth: getImage(image3).width,
                        margin: '0 auto' // used to center the image
                      }}
                      loading='eager'
                    />
                    <div className='is-flex justify-center content'>
                      <p className='has-text-centered has-text-gold-black'>
                        {course.plan.abc.image.title}
                      </p>
                    </div>
                  </div>
                </ScrollOnAnimation>
              </div>
            </div>
            <div className='extras-item'>
              <ScrollOnAnimation>
                <div className='has-text-centered is-margin-bottom-6'>
                  <h4>{course.plan.warmup.title}</h4>
                  <h6>{course.plan.warmup.subtitle}</h6>
                  <p>
                    <Markdown>{course.plan.warmup.description}</Markdown>
                  </p>
                </div>
              </ScrollOnAnimation>
              <div className='columns is-multiline is-mobile is-centered is-vcentered direction-row-reverse'>
                <ScrollOnAnimation>
                  <div className='column is-6-desktop is-full-touch is-offset-1-desktop'>
                    <div className='accordion is-fixed-on-open'>
                      <input type='checkbox' id='warmup' hidden />
                      <label
                        className='accordion-header is-margin-left-3'
                        htmlFor='warmup'
                      >
                        <a className='button is-text has-text-weight-bold'>
                          {course.plan.expand}
                        </a>
                      </label>
                      {course.plan.warmup.bullets.map((bullet) => (
                        <div key={bullet.title} className='is-relative'>
                          <FontAwesomeIcon
                            icon={faHeart}
                            className='has-text-nude is-size-7 is-absolute'
                            style={{ top: 8 }}
                          />
                          <div className='is-margin-left-5'>
                            <p className='accordion-mark'>{bullet.title}</p>
                            <div className='accordion-body'>
                              <p className='is-margin-bottom-4'>
                                <Markdown>{bullet.description}</Markdown>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </ScrollOnAnimation>
                <ScrollOnAnimation>
                  <div className='column is-10-mobile is-8-tablet is-5-desktop'>
                    <GatsbyImage
                      image={getImage(image7)}
                      title={course.plan.warmup.image.title}
                      alt={course.plan.warmup.image.alt}
                      className='live-image'
                      style={{
                        maxWidth: getImage(image7).width,
                        margin: '0 auto' // used to center the image
                      }}
                      loading='eager'
                    />
                    <div className='is-flex justify-center content'>
                      <p className='has-text-centered has-text-gold-black'>
                        {course.plan.warmup.image.title}
                      </p>
                    </div>
                  </div>
                </ScrollOnAnimation>
              </div>
            </div>
            <div className='extras-item'>
              <ScrollOnAnimation>
                <div className='has-text-centered is-margin-bottom-6'>
                  <h4>{course.plan.figures.title}</h4>
                  <p>
                    <Markdown>{course.plan.figures.description}</Markdown>
                  </p>
                </div>
              </ScrollOnAnimation>
              <div className='columns is-multiline is-mobile is-centered is-vcentered'>
                <ScrollOnAnimation>
                  <div className='column is-6-desktop is-full-tablet'>
                    <div className='accordion is-fixed-on-open'>
                      <p>{course.plan.figures.prefix}</p>
                      <input type='checkbox' id='figures' hidden />
                      <label
                        className='accordion-header is-margin-left-3'
                        htmlFor='figures'
                      >
                        <a className='button is-text has-text-weight-bold'>
                          {course.plan.expand}
                        </a>
                      </label>
                      {course.plan.figures.bullets.map((bullet) => (
                        <div key={bullet.title} className='is-relative'>
                          <FontAwesomeIcon
                            icon={faHeart}
                            className='has-text-nude is-size-7 is-absolute'
                            style={{ top: 8 }}
                          />
                          <div className='is-margin-left-5'>
                            <p className='accordion-mark'>{bullet.title}</p>
                            <div className='accordion-body'>
                              <p className='is-margin-bottom-4'>
                                <Markdown>{bullet.description}</Markdown>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </ScrollOnAnimation>
                <ScrollOnAnimation>
                  <div className='column is-10-mobile is-8-tablet is-5-desktop is-offset-1-desktop'>
                    <GatsbyImage
                      image={getImage(image2)}
                      title={course.plan.figures.image.title}
                      alt={course.plan.figures.image.alt}
                      className='live-image'
                      style={{
                        maxWidth: getImage(image2).width,
                        margin: '0 auto' // used to center the image
                      }}
                      loading='eager'
                    />
                    <div className='is-flex justify-center content'>
                      <p className='has-text-centered has-text-gold-black'>
                        {course.plan.figures.image.title}
                      </p>
                    </div>
                  </div>
                </ScrollOnAnimation>
              </div>
              <div className='extras-item'>
                <ScrollOnAnimation>
                  <div className='has-text-centered is-margin-bottom-6'>
                    <h4>{course.plan.certificate.title}</h4>
                    <p>
                      <Markdown>{course.plan.certificate.description}</Markdown>
                    </p>
                  </div>
                </ScrollOnAnimation>
                <div className='columns is-multiline is-mobile is-centered is-vcentered'>
                  <ScrollOnAnimation>
                    <div className='column is-10-mobile is-8-tablet is-6-desktop'>
                      <GatsbyImage
                        image={getImage(image10)}
                        title={course.plan.certificate.image.title}
                        alt={course.plan.certificate.image.alt}
                        className='live-image'
                        style={{
                          maxWidth: getImage(image10).width,
                          margin: '0 auto' // used to center the image
                        }}
                        loading='eager'
                      />
                      <div className='is-flex justify-center content'>
                        <p className='has-text-centered has-text-gold-black'>
                          {course.plan.certificate.image.title}
                        </p>
                      </div>
                    </div>
                  </ScrollOnAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollOnAnimation>
        <div className='hero'>
          <div className='hero-body'>
            <div className='container content'>
              <Testimony
                testimony={course.testimonials.testimonials[0]}
                expand={course.testimonials.expand}
                isBottomHR={false}
              />
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <ScrollOnAnimation>
        <div className='hero'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <CTA />
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <h3 className='has-text-nude'>{course.howItWorks.title}</h3>
          </div>
        </div>
      </div>
      {[
        course.howItWorks.bullets.slice(0, 3),
        course.howItWorks.bullets.slice(3, 6)
      ].map((bullets, index) => (
        <div key={index} className='hero'>
          <div className='hero-body'>
            <div className='container content'>
              <div
                className={clsx(
                  'columns is-mobile is-multiline is-centered is-vcentered',
                  {
                    'direction-row-reverse': index % 2 === 1
                  }
                )}
              >
                <div
                  className={clsx(
                    'column is-full-mobile is-8-tablet is-6-desktop',
                    {
                      'is-offset-1-widescreen': index % 2 === 1
                    }
                  )}
                >
                  {bullets.map((bullet) => (
                    <ScrollOnAnimation key={bullet.title}>
                      <div className='columns is-flex-direction-column-touch'>
                        <div className='column is-narrow has-text-centered-touch is-padding-bottom-0'>
                          <FontAwesomeIcon
                            icon={faHeart}
                            className='has-text-nude is-size-7 is-relative'
                            style={{ top: 4 }}
                          />
                        </div>
                        <div className='column'>
                          <h4 className='has-text-centered-touch'>
                            {bullet.title}
                          </h4>
                          <p>
                            <Markdown>{bullet.description}</Markdown>
                          </p>
                        </div>
                      </div>
                    </ScrollOnAnimation>
                  ))}
                </div>
                <ScrollOnAnimation>
                  <div
                    className={clsx(
                      'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen is-margin-top-4-touch',
                      {
                        'is-offset-1-widescreen': index % 2 === 0
                      }
                    )}
                  >
                    <GatsbyImage
                      image={getImage(HOW_IT_WORKS_IMAGE[index])}
                      title={course.howItWorks.images[index].title}
                      alt={course.howItWorks.images[index].alt}
                      className='live-image'
                      style={{
                        maxWidth: getImage(HOW_IT_WORKS_IMAGE[index]).width,
                        margin: '0 auto' // used to center the image
                      }}
                      loading='eager'
                    />
                    <div className='is-flex justify-center content'>
                      <p className='has-text-centered has-text-gold-black'>
                        {course.howItWorks.images[index].title}
                      </p>
                    </div>
                  </div>
                </ScrollOnAnimation>
              </div>
            </div>
          </div>
        </div>
      ))}
      <ScrollOnAnimation>
        <div className='hero'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <CTA />
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <ScrollOnAnimation>
        <div className='hero has-background-nude-lighter'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <FontAwesomeIcon
                icon={faAward}
                className='has-text-nude is-margin-bottom-4'
                size='2x'
              />
              <div className='is-margin-bottom-4'>
                <h3>{course.responsibility.title}</h3>
              </div>
              <p className='is-margin-bottom-6'>
                <Markdown>{course.responsibility.description}</Markdown>
              </p>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <div className='hero'>
        <div className='hero-body is-padding-bottom-0'>
          <div className='container content has-text-centered'>
            <h3>{course.whoIsItFor.title}</h3>
          </div>
        </div>
      </div>
      {course.whoIsItFor.bullets.map((bullet, index) => (
        <div key={index} className='hero'>
          <div className='hero-body'>
            <div className='container content'>
              <div
                className={clsx(
                  'columns is-mobile is-multiline is-centered is-vcentered',
                  {
                    'direction-row-reverse': index % 2 === 1
                  }
                )}
              >
                <div
                  className={clsx(
                    'column is-full-mobile is-8-tablet is-6-desktop',
                    {
                      'is-offset-1-widescreen': index % 2 === 1
                    }
                  )}
                >
                  <div className='content'>
                    <h4 className='has-text-centered-touch'>{bullet.title}</h4>
                    {bullet.bullets.map((bullet) => (
                      <ScrollOnAnimation key={bullet}>
                        <div className='columns is-flex-direction-column-touch'>
                          <div className='column is-narrow has-text-centered-touch is-padding-bottom-0'>
                            <FontAwesomeIcon
                              icon={index === 0 ? faXmark : faCheck}
                              className='has-text-nude is-size-7 is-relative'
                            />
                          </div>
                          <div className='column'>
                            <p>{bullet}</p>
                          </div>
                        </div>
                      </ScrollOnAnimation>
                    ))}
                  </div>
                </div>
                <ScrollOnAnimation>
                  <div
                    className={clsx(
                      'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen is-margin-top-4-touch',
                      {
                        'is-offset-1-widescreen': index % 2 === 0
                      }
                    )}
                  >
                    <GatsbyImage
                      image={getImage(WHO_IS_IT_FOR_IMAGE[index])}
                      title={bullet.image.title}
                      alt={bullet.image.alt}
                      className='live-image'
                      style={{
                        maxWidth: getImage(WHO_IS_IT_FOR_IMAGE[index]).width,
                        margin: '0 auto' // used to center the image
                      }}
                      loading='eager'
                    />
                    <div className='is-flex justify-center content'>
                      <p className='has-text-centered has-text-gold-black'>
                        {bullet.image.title}
                      </p>
                    </div>
                  </div>
                </ScrollOnAnimation>
              </div>
            </div>
          </div>
        </div>
      ))}
      <ScrollOnAnimation>
        <div id='registration' className='hero has-background-nude-light'>
          <div className='hero-body'>
            <div className='container content'>
              <div className='columns is-centered'>
                <div className='column is-12-mobile is-9-tablet is-6-desktop'>
                  <p className='is-margin-bottom-6'>
                    <Markdown>{course.pricing.description}</Markdown>
                  </p>
                  <div className='has-text-centered'>
                    <button
                      className='button is-nude is-medium'
                      onClick={() => setIsPaymentForm(true)}
                    >
                      {course.pricing.callToAction}
                    </button>
                  </div>
                  <p className='help has-text-centered is-size-6 is-margin-top-6'>
                    {i18n.courses.paymentInfo.pci}
                  </p>
                  <p className='is-margin-top-6 has-text-centered is-italic'>
                    <Markdown>{course.pricing.talkToMeDescription}</Markdown>{' '}
                    <a
                      href='https://api.whatsapp.com/send?phone=972504998822&text=%D7%94%D7%99%D7%99%20%D7%A7%D7%90%D7%A8%D7%99%D7%9F,%20%D7%90%D7%A0%D7%99%20%D7%9E%D7%90%D7%95%D7%93%20%D7%A8%D7%95%D7%A6%D7%94%20%D7%9C%D7%94%D7%A6%D7%98%D7%A8%D7%A3%20%D7%9C%D7%AA%D7%95%D7%9B%D7%A0%D7%99%D7%AA%20*%D7%A1%D7%A4%D7%A8%D7%99%D7%A0%D7%98%20%D7%A4%D7%99%D7%A1%D7%95%D7%9C%20%D7%93%D7%9E%D7%95%D7%99%D7%95%D7%AA%20%D7%91%D7%91%D7%A6%D7%A7%20%D7%A1%D7%95%D7%9B%D7%A8*%20%D7%95%D7%99%D7%A9%20%D7%9C%D7%99%20%D7%A9%D7%90%D7%9C%D7%94'
                      className='button is-text is-link-ka'
                      style={{
                        padding: 0,
                        alignItems: 'unset',
                        border: 'unset'
                      }}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {course.pricing.talkToMeCTA}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      {course.testimonials && (
        <>
          <div className='hero'>
            <div className='hero-body'>
              <div className='container content'>
                <ScrollOnAnimation>
                  <div className='has-text-centered is-margin-bottom-6'>
                    <h3>{course.testimonials.title}</h3>
                  </div>
                </ScrollOnAnimation>
                {course.testimonials.testimonials
                  .slice(1)
                  .map((testimony, index) => (
                    <ScrollOnAnimation key={testimony.title}>
                      <>
                        <Testimony
                          testimony={testimony}
                          expand={course.testimonials.expand}
                          isBottomHR={
                            index < course.testimonials.testimonials.length - 2
                          }
                        />
                      </>
                    </ScrollOnAnimation>
                  ))}
              </div>
            </div>
          </div>
          <div className='hero'>
            <div className='hero-body has-text-centered content'>
              <CTA />
            </div>
          </div>
        </>
      )}
      <ScrollOnAnimation>
        <div className='hero has-background-nude-lighter'>
          <div className='hero-body'>
            <div className='container'>
              <div className='columns is-mobile is-multiline is-centered'>
                <div className='column content is-margin-bottom-0 is-full-mobile is-10-tablet is-6-desktop'>
                  <h3>{course.about.title}</h3>
                  <p>
                    <Markdown>{course.about.description}</Markdown>
                  </p>
                </div>
                <div className='column is-4-fullhd is-5-widescreen is-half-tablet is-10-mobile is-offset-2-fullhd is-offset-1-widescreen is-order-3-touch'>
                  <GatsbyImage
                    image={getImage(survival)}
                    title={course.about.mainImage.title}
                    alt={course.about.mainImage.alt}
                    className='live-image'
                    style={{
                      maxWidth: getImage(survival).width,
                      margin: '0 auto' // used to center the image
                    }}
                  />
                  <div className='is-flex justify-center content'>
                    <p className='has-text-centered has-text-gold-black'>
                      {course.about.mainImage.title}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <ScrollOnAnimation>
        <div className='hero has-background-nude-lighter'>
          <div className='hero-body has-text-centered content'>
            <CTA />
          </div>
        </div>
      </ScrollOnAnimation>
    </>
  )
}

const FiguresSculpture = ({ location: { pathname, search } }) => {
  const product = {
    category: course.greenInvoiceItemId,
    name: course.name,
    price: course.pricing.paymentPrice * course.pricing.numOfPayments
  }
  const data = useStaticQuery(FiguresSculptureQuery)
  return (
    <Layout product={product}>
      <Seo
        metadata={course.metadata}
        pathname={pathname}
        og={{
          image: getSrc(data.image1),
          alt: course.mainImage.alt
        }}
        schemaMarkup={{
          breadcrumbs: [
            { name: i18n.header.academy, path: '/academy/' },
            {
              name: course.name,
              path: '/academy/figures-sculpture/'
            }
          ]
        }}
      />
      <FiguresSculptureTemplate
        pathname={pathname}
        search={search}
        data={data}
        product={product}
      />
    </Layout>
  )
}

export default FiguresSculpture
