import React from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteRight, faQuoteLeft } from '@fortawesome/free-solid-svg-icons'

const Testimony = ({ testimony, expand, isBottomHR = true }) => {
  return (
    <div className='columns is-centered'>
      <div className='column is-6-widescreen is-8-desktop is-10-tablet is-12-mobile'>
        <div
          className={clsx(
            'columns is-flex-direction-column is-mobile is-align-items-center',
            {
              'direction-column-reverse': testimony.isEnglish
            }
          )}
        >
          <div className='column is-align-self-flex-start'>
            <FontAwesomeIcon icon={faQuoteRight} size='lg' />
          </div>
          <div
            className='column is-padding-top-0 is-padding-bottom-0'
            dir={testimony.isEnglish ? 'ltr' : 'rtl'}
          >
            <h4 className='has-text-gold-black is-margin-bottom-0'>
              {testimony.title}
            </h4>
          </div>
          <div className='column is-align-self-flex-end'>
            <FontAwesomeIcon icon={faQuoteLeft} size='lg' />
          </div>
        </div>
        <div
          className={clsx('columns is-align-items-start', {
            'direction-row-reverse': testimony.isEnglish
          })}
        >
          <div className='column is-narrow'>
            <p className='has-text-centered has-text-weight-bold'>
              {testimony.name}
            </p>
          </div>
          <div className='column'>
            <p
              className='is-margin-bottom-0'
              dir={testimony.isEnglish ? 'ltr' : 'rtl'}
            >
              {testimony.text}
            </p>
            {testimony.moreText && (
              <div className='accordion is-fixed-on-open'>
                <input
                  type='checkbox'
                  id={`testimony_${encodeURIComponent(testimony.title)}`}
                  hidden
                />
                <div className='accordion-body'>
                  <p>{testimony.moreText}</p>
                </div>
                <label
                  className='accordion-header has-text-right'
                  htmlFor={`testimony_${encodeURIComponent(testimony.title)}`}
                >
                  <a className='button is-text has-text-weight-bold'>
                    {expand}
                  </a>
                </label>
              </div>
            )}
          </div>
        </div>
        {isBottomHR && <hr />}
      </div>
    </div>
  )
}

export default Testimony
